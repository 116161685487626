/**
 * Elections Block
 */

import React from 'react';
import { render } from 'react-dom';
import domContentLoaded from 'utils/domContentLoaded';
import ElectionsBlock from 'components/elections-block/index.tsx';

domContentLoaded(() => {
  const containers = [...document.querySelectorAll('[data-react-component="ElectionsBlock"]')];

  containers.forEach((container) => {
    const props = { ...container.dataset };

    render(
      <ElectionsBlock {...props} />,
      container
    );
  });
});
