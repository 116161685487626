/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';

const {
  wp: {
    i18n: {
      __,
    },
  },
} = window;

type ElectionProps = {
  electionId: string;
}

type Candidates = {
  name: string;
  party: string;
  percentage: string;
  votes: string;
  call_status: string;
  incumbent: string;
}

type ElectionState = {
  title: string;
  source: string;
  candidates: Candidates[];
  precinctsPercentage: string;
  race_call_status: string;
  showTooltip?: boolean;
}

function transformParty(value) {
  let party = '';
  if ('' !== value) {
    const partyLetter = value.substring(0, 1).toLowerCase();
    party = '(' === partyLetter.charAt() ? value.substring(1, 2).toLowerCase() : partyLetter;
  }
  return party;
}

function transformVotes(value) {
  const votes = new Intl.NumberFormat().format(value);
  return votes;
}

function decodeTitle(str) {
  return str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
}

const callStatuses = [
  'Apparent Winner',
  'Aparente Ganador',
  'Projected Winner',
  'Ganador Proyectado',
  'Winner',
  'Ganador',
  'Multiple Winners',
  'Ganadores',
  'Runoff',
  'Segunda Vuelta',
  'Too Close to Call',
  'Aún sin Determinar',
  'Too Early to Call',
  'Contienda Reñida',
];

class ElectionsBlock extends Component<ElectionProps, ElectionState> {
  constructor(props: ElectionProps) {
    super(props);

    this.state = {
      title: '',
      source: '',
      candidates: [],
      race_call_status: '',
      precinctsPercentage: '',
    };

    this.fetchData = this.fetchData.bind(this);
  }

  async fetchData(url) {
    if (! url) {
      return;
    }

    const response = await fetch(url);
    const data = await response.json();

    const {
      title: {
        rendered,
      },
      race_details: {
        source,
        candidates,
        precinctspercentage,
        race_call_status: raceCallStatus,
      },
    } = data;

    this.setState({
      title: rendered,
      source,
      candidates,
      precinctsPercentage: precinctspercentage,
      race_call_status: raceCallStatus,
    });
  }

  componentDidMount() {
    const electionsURL = `/wp-json/nbc/v1/elections/${this.props.electionId}`;
    this.fetchData(electionsURL);
  }

  toggleTooltip = () => {
    this.setState((prevState) => ({
      showTooltip: ! prevState.showTooltip,
    }));
  };

  render() {
    const {
      title,
      source,
      candidates,
      race_call_status: raceCallStatus,
      precinctsPercentage,
    } = this.state;

    const reporting = __('reporting', 'nbc');
    return (
      <ul>
        <li className="featured-race">
          <h2>{decodeTitle(title)}</h2>
          <div className="featured-race__meta">
            <p className="featured-race__reporting">
              {`${'' !== precinctsPercentage ? precinctsPercentage : '0'}% ${reporting}`}
            </p>
            { '' !== source &&
              <p className="featured-race__source">{__('Source', 'nbc')}: <span>{source}</span></p>
            }
          </div>
          <ul className="featured-race__candidates">
            {callStatuses.includes(raceCallStatus) &&
              <span className="featured-race__status">
                {raceCallStatus}
                {/* Keeping disabled for now
                <button
                  className="featured-race__status-button"
                  aria-label={callStatus}
                  type="button"
                  onClick={this.toggleTooltip}
                />
                {this.state.showTooltip &&
                  <p>tooltip text here</p>
                } */}
              </span>
            }
            {
              candidates.map(({ call_status: callStatus, name, percentage, votes, party }) =>
                <li className={`featured-candidate ${callStatuses.includes(raceCallStatus) && '' !== callStatus ? `featured-candidate__party-${transformParty(party)}` : ''}`}>
                  <div className="featured-candidate__info">
                    <div className="featured-candidate__toprow">
                      <h3 className="featured-candidate__name">
                        {name}
                        {callStatuses.includes(raceCallStatus) && '' !== callStatus &&
                        <span
                          aria-hidden="true"
                          className="icon icon-check"
                          title={callStatus}
                        />
                        }
                      </h3>
                      <h3 className="featured-candidate__percentage">{percentage}%</h3>
                    </div>
                    <div className="featured-candidate__bottomrow">
                      <span className="featured-candidate__votecount">{transformVotes(votes)}</span>
                      <progress className={transformParty(party)} value={percentage} max="100" />
                    </div>
                  </div>
                </li>)
            }
          </ul>
        </li>
      </ul>
    );
  }
}

export default ElectionsBlock;
